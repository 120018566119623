.productFilterButton {
    background: whitesmoke !important;

  padding-top:2px;
  padding-bottom:2px;
    padding-left:15px;
    padding-right: 15px;

    cursor:pointer;
    border-radius:10px ;
    color:#2C4B76 !important;
    border-color:coral



  }
  .selectedButton{
    padding-top:2px;
    padding-bottom:2px;
      padding-left:15px;
      padding-right: 15px;
  
      cursor:pointer;
      border-radius:10px ;
    color:white !important;
    background: #2C4B76  !important;
  }

  .productFilterButton:visited,  .productFilterButton:active,.productFilterButton:hover { 
    color:white !important;
    background: #2C4B76  !important;


   }

  .addToCartButton{
    margin-top: 70px !important;

    background-color:#2C4B76 !important;
    padding: 5px 15px !important;
    font-size: 12px;
    color:white;
    border-radius: 20px;
  }