body {
  overflow-x: hidden !important;
}

.lightBg {
  background: #a1bae1 !important;
}

.medBg {
  background: rgb(63, 108, 166) !important;
}

.darkBg,
.blueButton {
  background: rgb(44, 75, 118) !important;
}

.whiteBg,
.whiteButton {
  background: rgb(255, 255, 255) !important;
  color: rgb(12, 12, 12) !important;
  -webkit-box-shadow: 3px 2px 8px -3px rgba(0, 0, 0, 0.45);
  box-shadow: 3px 2px 8px -3px hsla(0, 0%, 0%, 0.45);
  -moz-box-shadow: 3px 2px 8px -3px hsla(0, 0%, 0%, 0.45);
}

.yellowBg,
.yellowButton {
  background: rgb(232, 178, 76) !important;
}

#export-dropdown,
.pinkBg,
.pinkButton {
  background: rgb(215, 73, 146) !important;
}

.trans {
  background: transparent !important;
  border: 1pix solid rgb(12, 12, 12);
}
.greenBg,
.greenButton {
  background: #28a745 !important;
}

.redBg,
.redButton {
  background: #c82333 !important;
}

.lightText {
  color: #a1bae1 !important;
}

.medText {
  color: rgb(63, 108, 166) !important;
}

.darkText {
  color: rgb(44, 75, 118) !important;
}

.breadCrumb:hover {
  text-decoration: underline !important;
}
.productNav {
}

.blackText {
  color: #000000 !important;
}
.yellowText {
  color: rgb(232, 178, 76) !important;
}

.redText {
  color: #d0532a !important;
}

.redBackground {
  background: #d0532a !important;
}

.greenText {
  color: #259867 !important;
}

.pinkText {
  color: rgb(215, 73, 146) !important;
}

.semiBold {
  font-weight: 500 !important;
}

.bold {
  font-weight: 800 !important;
}

.normalText {
  font-weight: normal !important;
}

.activeProgressNumberCircle,
.inactiveProgressNumberCircle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeProgressNumberCircle p,
.inactiveProgressNumberCircle p {
  background: rgb(44, 75, 118);
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactiveProgressNumberCircle p {
  background: rgb(180, 180, 180);
}

.flexRow {
  display: flex;
}

.form-label {
  font-weight: normal;
}

.form-control {
  resize: none;
}

.tableSearch {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid #ced4da;
  padding: 8px 0px;
  margin-bottom: 16px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.borderless {
  border: none;
}

.dropdownArrow {
  position: absolute;
  right: 20px;
  top: 37px;
}

.pointer {
  cursor: pointer;
}

.blueButton,
.yellowButton,
.pinkButton,
.lightButton,
.whiteButton,
.greenButton,
.redButton {
  outline: none !important;
  border: none !important;
  border-radius: 5px;
  padding: 8px 30px !important;
  color: rgb(255, 255, 255);
  transition: all 0.2s;
  width: fit-content;
}

.lightButton,
.muiLightButton {
  background: #fff;
  border: 1px solid gray !important;
  color: #000 !important;
}

.blueButton:hover,
.yellowButton:hover,
.pinkButton:hover,
.greenButton:hover,
.redButton:hover {
  -webkit-box-shadow: 3px 3px 8px -5px #000000;
  box-shadow: 3px 3px 8px -5px #000000;
  color: #fff;
}
.productNavLinkText {
  color: #2c4b76;
}

.productNavLinkText:hover {
  background: #2c4b76;
  color: white;
}

.lightBlackText {
  color: rgb(0, 0, 0, 0.9) !important;
  background: #ffff !important;
}

.blueButton:disabled,
.yellowButton:disabled,
.pinkButton:disabled,
.greenButton:disabled,
.redButton:disabled {
  background: rgb(0, 0, 0, 0.3) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.9;
}

.flexJCAC {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.spinnerOverlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 0.2);
  z-index: 999999;
  top: 0;
  left: 0;
}

.staffProfileImgCircle {
  height: 150px;
  width: 150px;
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 50%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  object-position: center;
  object-fit: contain;
}

.requiredStar {
  color: rgb(215, 73, 146) !important;
}

.conversationMessage {
  overflow-wrap: anywhere;
}

.invoiceHeader {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.invoiceHeader div {
  height: 35px;
  width: fit-content;
}

.invoiceHeader h4 {
  width: fit-content;
}

.prescriptionRightInfo,
.shadowedDiv {
  -webkit-box-shadow: 1px 1px 10px -5px #000000;
  box-shadow: 1px 1px 10px -5px #000000;
  width: 100%;
  border-radius: 10px;
}

.accordionsSearchRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.accordionsSearchRow div {
  width: 240px;
}

.accordion-search-icon {
  position: absolute;
  left: 15px;
  bottom: 7px;
}

.accordiansSearch {
  padding: 0 20px;
  margin-top: 40px;
  width: 100%;
}

.accordionProduct {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid rgb(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s;
}

.accordionProduct:hover {
  background: rgb(0, 0, 0, 0.01) !important;
}

.accordion-button {
  background: rgb(0, 0, 0, 0.03);
}

.modal-content {
  height: 80vh;
  z-index: 10;
}

.sigButtonBack,
.sigButtonNext {
  position: absolute;
  bottom: 10px;
  left: 20px;
  background: none;
  outline: none;
  border: none;
}

.sigButtonNext {
  left: unset;
  right: 20px;
}

.font-size-12 {
  font-size: 12px !important;
}

.newPrescriptionForm {
  max-width: 800px !important;
}

.newPrescriptionFormStep1 {
  max-width: 1500px !important;
}

.smallHeightmodal .modal-content {
  height: 300px !important;
}
.mediumHeightmodal .modal-content {
  height: 400px !important;
}
.largeHeightmodal .modal-content {
  margin-top: 40px !important;
  height: fit-content !important;
}
.fitHeightmodal .modal-content {
  height: fit-content !important;
}
.stepsForm {
  padding: 0 150px !important;
}

.stepsForm button {
  width: 250px !important;
}

.InfoRow {
  display: flex;
}

.InfoRow p {
  width: 50%;
}

.InfoRow p:first-child {
  font-weight: bold;
  padding-right: 15px;
}

.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 0.3s infinite linear alternate;
  animation-delay: 0.2s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #2c4b76;
  color: #2c4b76;
  animation: dotFlashing 0.3s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #2c4b76;
  color: #2c4b76;
  animation: dotFlashing 0.3s infinite alternate;
  animation-delay: 0.4s;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}

.signature-wrapper {
  position: relative;
  width: 400px;
  height: 200px;
  background-color: whitesmoke;
}

.signature {
  position: absolute;
  width: 100%;
  height: 100%;
}

@keyframes dotFlashing {
  0% {
    background-color: #2c4b76;
  }
  50%,
  100% {
    background-color: #e6eaff;
  }
}

@media print {
  html,
  body,
  .no-shadow {
    font-size: 12px !important;
  }
  .page-break {
    /* margin-top: 1rem; */
    display: block;
    page-break-before: always;
  }
  .page-break > div,
  .no-shadow > div {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

@page {
  margin-top: 0.2in;
}

@media (min-width: 1200px) {
  .stepsPillsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .stepsPillsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stepsForm {
    padding: 0 80px !important;
  }

  .stepsPillsContainer {
    flex-wrap: wrap;
  }

  .stepsPillsContainer > div {
    width: 90vw;
  }
}

@media (min-width: 992px) {
  .messageLefSidebar {
    height: fit-content;
  }
}

@media (max-width: 992px) {
  .accordionsSearchRow div {
    width: 100%;
    margin-bottom: 30px;
  }

  .stepsForm {
    padding: 0 40px !important;
  }
}

@media (max-width: 768px) {
  .stepsForm {
    padding: 0 20px !important;
  }

  .stepsForm button {
    width: 150px !important;
  }

  .blueButton,
  .yellowButton,
  .pinkButton,
  .lightButton {
    padding: 6px 20px !important;
    font-size: 12px;
  }

  .invoicesSumText {
    font-size: 14px;
  }

  .activeProgressNumberCircle p,
  .inactiveProgressNumberCircle p {
    height: 30px;
    width: 30px;
    padding: 3px;
    font-size: 12px !important;
  }

  .rounded-pill {
    padding: 0 !important;
  }

  .rounded-pill p {
    font-size: 12px !important;
  }
}

@media (max-width: 576px) {
  #registerForm {
    padding: 0 !important;
  }

  #registerForm > div {
    padding: 0 10px !important;
    font-size: 12px !important;
  }

  p {
    font-size: 12px !important;
  }

  .rounded-pill p {
    font-size: 10px !important;
  }
}

.table > :not(caption) > * > * {
  background-color: white !important;
}
table {
  white-space: normal !important;
}

/* .cardWithBackground {
  background-image: url('../images/map.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
} */

.cardWithBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/map.png"); /* Background image URL */
  opacity: 0.2; /* Adjust the opacity value as needed (0.0 to 1.0) */
  background-size: contain; /* Adjust as needed */
  background-repeat: no-repeat;
  background-position: center center; /* Adjust as needed */
}

input#cvc.credit-card-input {
  display: "none" !important;
}
