.offline-button{
    background: #2C4B76 !important;
    color: white !important;
    cursor: pointer ;
    padding: 10px !important;
}

.online-button{
    background: white !important;
    border: 1px solid  #2C4B76 !important;
    color: black !important;
    cursor: pointer ;
    padding: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-weight: bold !important;
}