.clinic-products-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.clinic-products-header .yellowButton {
  margin-right: 15px;
}

.clinic-product-input-container input{
  max-width: 200px !important;
}

.products-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}

.products-header h4 {
  flex: 1;
  text-align: center;
  align-content: center;
  alignment-baseline: center;
}

.product-table-header {
  padding: 10px 10px 10px 10px;
  background-color: white;
  font-weight: 600;
  border: 0px;
  border-bottom: solid 1.5px #eff2f7;
}

.product-table-body {
  padding: 20px 10px 20px 10px;
  border: 0px;
  border-bottom: solid 1px #eff2f7;
  max-width: 120px;
}

.product-table-container {
  width: 100%;
  overflow-x:scroll !important;
}

.product-table-inactive {
  opacity: 0.5;
}


.button-product-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
}
.clinic-product-action-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  column-gap: 20px;
  align-items: center;
  font-size: 20px;
}

.clinic-product-input-container .input-group-append button{
border: solid 1px #ced4da !important;
}

.product-status-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.button-product-action-container i {
  cursor: pointer;
}

.product-search-button {
  position: absolute;
  top: -3px;
  right: -28px;
  background: #2c4b76;
  color: #fff;
}

.product-search-icon {
  position: absolute;
  top: 4px;
  right: 10px;
}

.product-form .form-label {
  width: 100%;
  text-align: left;
}

.add-product-modal .overflow-hidden.card {
  background-color: transparent;
  box-shadow: 0 0.75rem 1.5rem transparent;
}

.smallHeightmodal.add-product-modal .modal-content {
  height: auto !important;
}


.smallHeightmodal.add-product-modal .overflow-hidden {
  margin-bottom: 0px;
}

.product-card-footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 5px;
}

.bulk-discount-card-footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  column-gap: 5px;
}

.add-product-modal .card-footer {
  display: flex;
  width: 100%;
  background-color: white;
}


.error-product-required {
  font-size: 9px;
  text-align: start;
  color: red;
  width: 100%;
  display: flex;
}

.bulk-product-msg{
  color: #e8b24c;
font-size: 10px;
}

.empty-record-table{
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}



/** Toggle **/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: green;
}
input + .slider {
  background-color: red;
}

input:focus + .slider {
  box-shadow: 0 0 1px green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.disable-row{
  pointer-events: none;
  opacity: 50%;
}

//Slider 2 Hold
.slide{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slide:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slide {
  background-color: green;
}
input + .slide {
  background-color: #ccc;
}

input:focus + .slide {
  box-shadow: 0 0 1px green;
}

input:checked + .slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.slide.round {
  border-radius: 34px;
}

.slide.round:before {
  border-radius: 50%;
}

.disable-row{
  pointer-events: none;
  opacity: 50%;
 
}


/**/

