.nested-table-section {
  animation-duration: 1.5s;
  transition: max-height 0.15s ease-out;
  animation-timing-function: ease;
  border-top: none !important;
  border-style: hidden !important;
  
}
.nested-table-section td {
  white-space: 'normal' !important;
  max-height:400px !important;
  max-width: 250px !important;
  overflow:auto;
}


.noEndBorder tr:last-of-type {
  border-style: hidden !important;
  border-style: none !important
}