.set-balance-icon {
  background: #2c4b76 !important;
  color: white;
  cursor: pointer;
  width: 30px;
  border: 1px solid #2c4b76;
}

.credit-balance-list {
  border: 1px solid grey;
  padding: 4.5px;
}

.credit-balance-profile {
  border: 1px solid grey;
  text-align: center;
  padding: 4.5px;
}

.credit-balance-use {
  border-radius: 5px;
  padding: 5px;
}
