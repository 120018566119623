.payment-grid-box {
  padding: 20px;
  height: 125px;
  max-height: fit-content !important;
  border-radius: 5px;
  margin-bottom: 30px;
}

.total-balance-box {
  background-color: #2c4b76;
  color: #fff;
  .invoice-balance-text {
    margin-bottom: 10px;
    font-size: 10px;
  }
  .pay-full-box {
    background-color: #fff;
    color: black;
    border-radius: 5px;
    padding: 10px;
    height: 55px;
    .btn-primary {
      background-color: #2c4b76;
      color: white;
    }
  }
}

.invoice-due-box {
  background-color: #fff;
  border: 1px solid #2c4b76;
}

.pay-invoice-box {
  background-color: #fff;
  border: 1px solid #2c4b76;
  .creditUseButton {
    background-color: #d74992;
    color: white;
    border-radius: 5px;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
  }
}

.export-box {
  background-color: #fff;
  border: 1px solid #2c4b76;
}
