.lo-navbar {
  height: 50px;
  padding-top: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  background: transparent !important;
}

.lo-navbar img {
  height: 40px;
  margin-left: 30px;
}

.navLinks {
  align-self: flex-end;
  height: 100%;
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.navLinks a {
  font-size: 16px;
  margin: 0 30px;
  color: #444444;
}

.navLinks a:hover {
  color: rgb(63, 108, 166) !important;
}

@media (max-width: 768px) {
  .lo-navbar img {
    margin-left: 20px;
    height: 35px;
  }

  .navLinks a {
    margin: 0 20px;
    font-size: 14px !important;
  }
}

@media (max-width: 576px) {
  .lo-navbar img {
    margin-left: 10px;
    height: 30px;
  }

  .navLinks a {
    margin: 0 10px;
    font-size: 12px !important;
  }
}
