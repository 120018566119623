.dashboardCard {
  padding: 0 10px;
  display: flex;
}

.dashboardCardContent {
  background: #fff;
  border-radius: 7px;
  padding: 20px 30px;
  display: flex;
  margin: 0 5px;
  align-items: center;
  width: 100%;
  -webkit-box-shadow: 1px 1px 5px -5px rgb(0, 0, 0, 0.3);
  box-shadow: 1px 1px 5px -5px rgb(0, 0, 0, 0.3);
  margin-bottom: 10px;
}
.apexcharts-yaxis text,
.apexcharts-xaxis text {
  fill: #74788d !important;
}

.flexCards {
  display: flex;
  justify-content: center;
  padding-right: 0;
}

.flexCards .dashboardCardContent {
  width: 230px;
  flex: auto;
}

.dashboardCardAdmin {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboardCardContentAdmin {
  background: #fff;
  border-radius: 7px;
  padding: 20px 30px;
  display: flex;
  flex: 0 0 23.75%;
  min-width: 250px;
  margin: 0 5px;
  margin-bottom: 10px;
  align-items: center;
  transition: all 0.3s;
}
.dashboardCardContentAdmin:hover {
  transform: translate(0px, -3px);
  -webkit-box-shadow: 0px 30px 50px -40px rgba(90, 102, 133, 0.6);
  -moz-box-shadow: 0px 30px 50px -40px rgba(90, 102, 133, 0.6);
  box-shadow: 0px 30px 50px -40px rgba(90, 102, 133, 0.6);
  z-index: 23;
}
.dashboardCardContentAdmin:active {
  transition: all 0.05s;
  transform: translate(0px, 0px);
}
.largeCard {
  justify-content: flex-start;
  height: 400px;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  padding: 20px 10px;
}

.dashboardCard i,
.dashboardCardAdmin i {
  margin-right: 20px;
  font-size: 26px;
}

.dashboardCard p,
.dashboardCardAdmin p {
  margin: 0;
}

.dashboardCard .number,
.dashboardCardAdmin .number {
  font-size: 24px;
  font-weight: bold;
}

.flexColumn {
  flex-direction: column;
}

.newRXText {
  font-size: 16px;
}

.greenText {
  color: #24a26d;
}
.blueText {
  color: #2c4b76;
}

.productNav {
  color: #fbfdfe;
  border: 1px solid #9399a0;
}
.orangeText {
  color: #ff8514;
}

.justifySpace {
  display: flex;
  justify-content: space-between;
}

.searchIcon {
  color: rgb(0, 0, 0, 0.5);
  font-size: 16px !important;
  margin-left: 5px;
  cursor: pointer;
}

.searchInput {
  border-radius: 5px;
  margin: 0 5px;
  border: 1px solid rgb(0, 0, 0, 0.2);
  padding: 3px 5px;
  width: 38%;
}

.searchResultDate {
  font-size: 12px;
  color: rgb(0, 0, 0, 0.6);
}

.listHeader {
}
.listHeader:hover {
  background-color: #f8f8fb;
}
