.navbar {
  z-index: 5;
  width: 100vw;
  background-color: rgb(44, 75, 118) !important;
  padding: 10px 0;
  height: 60px;
}

.navLink { 
  color: rgb(255, 255, 255, 0.6) !important; 
  margin: 0 10px;
  transition: all 0.3s;
  font-size: 13px;
}

.navDD {
  color: rgb(0, 0, 0, 0.7) !important;
}

.navDD:hover {
  color: rgb(0, 0, 0, 1) !important;
}

.navDDLink {
  color: rgb(0, 0, 0, 0.6) !important;
  font-size: 12px;
}

.navLink:hover, .activeLink {
  transition: all 0.3s;
  color: #fff !important;
}

.activeLinkDark {
  color: #000 !important;
}

.navDDLink:hover {
  color: rgb(0, 0, 0, 1) !important;
}

.navDD div {
  width: 170px;
}

.profileDD div {
  right: 0;
}

.navDDArrow {
  position: absolute;
  right: 0;
}

.navbarBrand {
  margin-left: 20px;
  z-index: 999;
}

.navbarCollapse {
  width: 100vw;
  flex-direction: row-reverse;
}

.navbarToggle {
  z-index: 999;
  padding: 10px;
  border: none !important;
  outline: none !important;
  position: absolute;
  right: 15px;
  top: 15px;
}

.navbarToggleIcon {
  height: 20px;
  width: 25px;
  border: none !important;
  outline: none !important;
}

.navbarToggleIcon:hover {
  opacity: 0.8;
}

.navLogo {
  height: 40px;
}

.logoutIcon {
  color: #9c223a;
  opacity: 1 !important;
}

.navUserCircle {
  font-size: 16px;
  margin-right: 5px;
}

@media (max-width: 900px) {
  .navLink {
    font-size: 11px !important;
  }
}

@media (max-width: 767px) {
  .navLink {
    font-size: 13px !important;
  }

  .navbarCollapse {
    height: 0 !important;
  }

  .navbarContent {
    background-color: rgb(44, 75, 118);
    text-align: center;
    padding-top: 60px !important;
    width: 100vw;
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    min-height: 100vh !important;
  }

  .navLink, .navDD a {
    margin: 0;
    padding: 25px;
    color: #fff !important;
  }

  .navLink:hover, .activeLink {
    background: rgb(255, 255, 255, 0.1) !important;
  }

  .navDD {
    padding: 0 !important
  }

  .navDD:hover {
    background: none;
  }

  .navDD div {
    width: 100vw;
    background: none;
    padding: 0;
  }

  .navDD div a {
    width: 100vw;
    background: none;
    text-align: center;
  }
  
  .dropdown-divider {
    display: none;
  }

  .navDDLink:hover {
    color: rgb(255, 255, 255, 1) !important;
  }
}