#loginForm input::placeholder {
}

#loginForm input {
  color: #2c4b76;
  background-color: transparent;
  border: 1px solid #2c4b76;
}

.loginBtn {
  background-color: #dee2e8;
  color: #2c4b76;
  font-size: 16px;
}

.loginBtn:hover {
  background-color: #2c4b76;
  color: white;
}

.forgot-Password {
  color: #2c4b76 !important;
  font-size: 12px;
}
.forgot-Password a {
  color: #2c4b76 !important;
  font-size: 12px;
}

.registerLink a {
  color: #2c4b76 !important;
}
.registerLink {
  color: #2c4b76 !important;
}

.left-side-content img {
  width: 639px;
  height: 254px;
  display: block !important;
}

.right-grid {
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-size: contain !important;
  min-height: 100vh;
}
.password-wrapper input::-ms-reveal {
  display: none;
}
.password-wrapper {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the value to control the icon's position */
  transform: translateY(-50%);
  cursor: pointer;
  display: block;
  color: #2c4b76 !important;
}

/* For Desktop View */
@media screen and (min-width: 1024px) {
  .left-grid {
    display: none;
  }
  .left-side-content img {
    display: none;
  }
}
/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .left-grid {
    display: none;
  }
  .left-side-content img {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  #leftGrid {
    display: none !important;
  }

  .mobileLogo {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .mobileLogo img {
    display: block !important;
    text-align: center !important;
  }
}
