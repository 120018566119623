.shipping-input{

    padding:5px;
 
    width: 100%;

    border: 1px solid grey;
    border-radius: 5px;


    margin-top:10px;
    margin-bottom:10px;
}