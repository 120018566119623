.category-section{
  width: 100%;
}


.collapsible-section-body {
  animation-duration: 1.5s;
  transition: max-height 0.15s ease-out;
  animation-timing-function: ease;
}

.collapsible-section-header {
  padding: 10px;
  background-color: #f6f6f6;

  height: 50px;
}
.collapsible-section-header .table-width{
  padding: 10px;
  background-color: #f6f6f6;
  cursor: pointer;
  height: 50px;
}

/*
td{
  width: 290px !important;
  overflow: hidden !important;
}
*/
.collapsible-section-body   td {
  white-space: 'normal' !important;
  max-height:400px !important;
  max-width: 400px !important;
  overflow:auto;
}

.table-width {
   min-width: 40px;
}
.rx-collapsible-table thead tr td{
  min-width: 200px;

  padding: 10px;
}

tbody.rx-collapsible td{
  padding: 5x;
}

button {
  border:none !important;
  background-color: transparent;
}

.rx-parent-div-table{
  overflow-x:auto !important;
}


/** Toggle **/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: green;
}
input + .slider {
  background-color: red;
}

input:focus + .slider {
  box-shadow: 0 0 1px green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.disable-row{
  pointer-events: none;
  opacity: 50%;
}

//Slider 2 Hold
.slide{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slide:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slide {
  background-color: green;
}
input + .slide {
  background-color: #ccc;
}

input:focus + .slide {
  box-shadow: 0 0 1px green;
}

input:checked + .slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.slide.round {
  border-radius: 34px;
}

.slide.round:before {
  border-radius: 50%;
}

.disable-row{
  pointer-events: none;
  opacity: 50%;
 
}


/**/

