.empty-state {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: #c0c0c0;
  margin-top: 100px;
}
.vpi-gantt-chart {
  margin: 0px;
  padding: 0px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  border: 1px solid #eff2f7;

  .left-panel {
    max-width: 25%;
    width: 100%;
    border-right: 3px solid #fafafa;
    box-shadow: -1px 2px 3px 1px #cecece;

    .header {
      height: 70px;
      background-color: #2c4b76;
      color: #fefefe;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: medium;
    }
    .medication-list {
      .medication-item {
        transition: all ease-out 0.3s;
        cursor: pointer;
        padding-left: 10px;
        height: 60px;
        border-bottom: 1px solid #eff2f7;
        &:last-child {
          border-width: 0px;
        }

        .product-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            line-height: 5;
            max-width: 85%;
            display: flex;
            align-items: center;
            span {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 13px;
              color: #222;
            }
            i {
              font-size: 15px;
              padding-right: 10px;
              height: 18px;
            }
          }
          .medication-progress {
          }
        }

        .medication-details {
          display: none;
          line-height: 1;
          visibility: visible;
        }
        &.is-active {
          height: 140px;
          transition: all ease-in 0.3s;
          .medication-details {
            display: block;
            visibility: visible;
            .info {
   
            }
          }
        }
      }
    }
  }
  .right-panel {
    overflow-x: auto;
    width: auto;
    max-width: 75%;
    width: 100%;
    box-shadow: 1px 2px 3px 1px #cecece;

    .header {
      height: 70px;
      width: fit-content;
      min-width: 100%;
      background-color: #2c4b76;
      // width: fit-content;
      .calendar-header {
        display: flex;
        height: 100%;
        padding: 5px 0px;
        .month-header-container {
          width: fit-content;
          border-right: 2px solid #fafafa;
          &:is(:last-child) {
            border: 0px;
          }
          .month-title {
            text-align: center;
            font-size: 15px;
            color: #fafafa;
            font-weight: 400;
          }
          .month-dates-container {
            display: flex;
            .date-cell {
              min-height: 25px;
              min-width: 25px;
              display: inline-block;
              text-align: center;
              line-height: 2.5;
              color: #fafafa;
            }
          }
        }
      }
    }
    .medications-history-container {
      height: calc(100% - 70px);

      .medication-history-item {
        &:last-child {
          border-width: 0px;
        }

        &.is-active {
          height: 140px;
        }
        &:hover {
          .medication-history-bar {
            background-color: #546cde;
          }
        }
        background-color: white;
        border-bottom: 1px solid #eff2f7;
        position: relative;
        height: 60px;
        display: flex;
        align-items: center;
        .medication-history-bar {
          background-color: #2C4B76;
          &.disabled {
            background-color: #dfdfdf;
          }
        }
      }
    }
  }
  .CircularProgressbar {
    height: 30px;
    margin-right: 10px;
    width: fit-content;
    .CircularProgressbar-text {
      font-size: 30px;
      font-weight: 500;
      color: #2c4b76;
      fill: #2c4b76;
    }
    .CircularProgressbar-path {
      fill: #2c4b76;
      stroke: #2c4b76;
    }
  }
}
