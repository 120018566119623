.modal-dialog {
  height: 50% !important;
}
.custom-modal-style {
  height: 50% !important;
}

.same-size-button {
  width: 50% !important;

  text-align: center;
  line-height: 1.1em;
  font-size: 1.1em;
  background-color: "blue";
}
